import * as React from 'react'

import LayoutRoot from '../components/LayoutRoot'
import { graphql } from 'gatsby'
// import PropTypes from 'prop-types'

import * as globalStyles from './../styles/Global.module.scss'
import * as styles from './../styles/VideoList.module.scss'
import './../styles/ArticleList.css'
import ReactPaginate from 'react-paginate'
import { navigate } from 'gatsby'
import { isFrontend } from '../utils/checkSide'

import ReactPlayer from 'react-player'
import { formatDate } from '../utils/format'
import { InfoCategoriesNav } from '../templates/ArticleList'

export interface VideoQueryProps {
  site: {
    siteMetadata: {
      title: string
      description: string
      keywords: string
    }
  }
  allStrapiVideo: {
    nodes: Video[]
    pageInfo: {
      perPage: number
      itemCount: number
      pageCount: number
      totalCount: number
      hasPreviousPage: boolean
      hasNextPage: boolean
      currentPage: number
    }
  }
}

const VideoList = ({ data }: { data: VideoQueryProps; pageContext: PageContext }) => {
  const pageData = data.allStrapiVideo.pageInfo
  const handlePageClick = ({ selected }: { selected: number }) => {
    if (selected === 0 && isFrontend) navigate('/video')
    else if (isFrontend) navigate(`/video/${selected + 1}`)
  }
  return (
    <LayoutRoot>
      <>
        <InfoCategoriesNav />
        <div className={styles.cardsWrapper}>
          {data.allStrapiVideo.nodes.map((video, index) => {
            const handleVideoClick = () => {
              if (isFrontend) navigate(`/video/${video.id}`)
            }
            return (
              <div key={index} className={styles.videoCard}>
                <div className={styles.videoContainer}>
                  <div className={`${styles.videoClickCover} ${globalStyles.pointer}`} onClick={handleVideoClick} />
                  <ReactPlayer
                    height={'100%'}
                    width={'100%'}
                    playing={false}
                    controls={false}
                    light={true}
                    url={video.videoLink}
                  />
                </div>
                <div className={styles.videoTitle}>{video.title}</div>
                <div className={styles.videoDate}>{formatDate(video.videoDate)}</div>
              </div>
            )
          })}
        </div>
        {pageData.pageCount > 1 && (
          <ReactPaginate
            className={`pagination ${globalStyles.pagnationOutLine}`}
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={pageData.perPage}
            marginPagesDisplayed={1}
            pageCount={pageData.pageCount}
            previousLabel="<"
            forcePage={pageData.currentPage - 1}
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            activeClassName="active"
          />
        )}
      </>
    </LayoutRoot>
  )
}

export const query = graphql`
  query VideoeListQuery($skip: Int!, $limit: Int!) {
    allStrapiVideo(skip: $skip, limit: $limit, sort: { fields: videoDate, order: DESC }) {
      pageInfo {
        perPage
        itemCount
        pageCount
        totalCount
        hasPreviousPage
        hasNextPage
        currentPage
      }
      nodes {
        id
        title
        content {
          data {
            content
          }
        }
        isTop
        videoLink
        videoDate
      }
    }
  }
`

export default VideoList
